import { gql } from '@apollo/client';

export const GET_LOGGED_IN_USER = gql`
  query getUser {
    me {
      _id
      name
      email
      address {
        _id
        type
        name
        info
      }
      contact {
        _id
        type
        number
      }
      card {
        _id
        type
        cardType
        name
        lastFourDigit
      }
    }
  }
`;
export const SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      _id
      name
      email
    }
  }
`;
export const LOGOUT = gql`
  mutation logout{
    logout    
  }
`;