import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { GET_LOGGED_IN_USER, SIGN_IN, LOGOUT } from '../graphql/query/user.query'

type AuthProps = {
  loaded: boolean;
  isAuthenticated: boolean;
  authenticate: ({ email, password }: { email: string, password: string }, cb: (error?) => void) => void;
  signout: (cb?: () => void) => void;
  user: any;
};

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {
  const { data, error, loading } = useQuery(GET_LOGGED_IN_USER, {
    fetchPolicy: "network-only"
  });

  const [signInMutation] = useMutation(SIGN_IN);
  const [logoutMutation] = useMutation(LOGOUT);

  React.useEffect(() => {
    if (!loading) {
      if (data && data.me) {
        setState({ isAuthenticated: true, user: data.me, loaded: true })
      } else {
        console.log("HOLA")
        setState({ isAuthenticated: false, user: null, loaded: true })
      }
    }
  }, [data, error, loading])
  const [state, setState] = React.useState<{ isAuthenticated: boolean, user: any, loaded: boolean }>({ isAuthenticated: false, user: null, loaded: false });

  function authenticate({ email, password }: { email: string, password: string }, cb: (error?) => void) {
    signInMutation({
      variables: {
        email,
        password
      },
    }).then(res => {
      if (res && res.data.signIn) {
        setState({ isAuthenticated: true, user: res.data.signIn, loaded: true })
        cb();
      } else {
        setState({ isAuthenticated: false, user: null, loaded: true })
      }
    }).catch(err => {
      cb(err);
      setState({ isAuthenticated: false, user: null, loaded: true })
      console.error(err);
    });
  }
  function signout(cb?: () => void) {
    logoutMutation().then(() => {
      setState({ isAuthenticated: false, user: null, loaded: true })
      if (cb) cb();
    }).catch(err => console.error(err))
  }
  return (
    <AuthContext.Provider
      value={{
        loaded: state.loaded,
        isAuthenticated: state.isAuthenticated,
        authenticate,
        signout,
        user: state.user
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
