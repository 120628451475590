import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider, ApolloClient, from, InMemoryCache } from '@apollo/client';
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client'

const linkError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const link = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include'
});
const uniqueProducts = (array: { __ref: string }[]) => {
  return array.filter((v, i, a) => a.findIndex(t => (t.__ref === v.__ref)) === i)
}
type productsType = { hasMore: boolean, totalCount: number, __typename: string, items: { __ref: string }[] }
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        products: {
          keyArgs: ['category', 'searchText', 'disabled'],
          merge(existing, incoming) {
            const { items: newItems } = incoming;
            return existing
              ? {
                ...incoming,
                items: uniqueProducts([...existing.items, ...newItems]),
              }
              : incoming;
          },
        }
      }
    }
  }
})
const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: cache,
  link: from([linkError, link]),
});
function App() {
  const engine = new Styletron();
  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
